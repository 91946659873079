<template>
  <div id="campaigns-container">
    <h2><i class="fad fa-fw fa-hashtag"></i> Campaign Planner</h2>
    <p class="lead">
      Easily collect, analyse and compare your Campaigns and connect them to your
      <router-link to="/links">Link Tree Pages</router-link>
      ,
      <router-link to="/journeys">Customer Journeys</router-link>
      ,
      <router-link to="/personas">Personas</router-link>
      ,
      <router-link to="/jobs">User Jobs</router-link>
      and
      <router-link to="/values">Value Propositions</router-link>
      .
      This will enable you a performance overview of your
      <router-link to="/contents">Content</router-link>
      and where you
      have chances to optimize for rankings to be found more easily on the internet.
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Facebook, Instagram, Twitter, Pinterest, LinkedIn, Xing, etc integrations</li>
      <li>Connect to <router-link to="/contents">Contents</router-link></li>
      <li>Connect to <router-link to="/channels">Channels</router-link></li>
      <li>Connect to <router-link to="/links">Links</router-link></li>
      <li>Connect to <router-link to="/values">Values</router-link></li>
      <li>Connect to <router-link to="/jobs">Jobs</router-link></li>
      <li>Connect to <router-link to="/journeys">Journeys</router-link></li>
    </ul>-->

  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Campaigns',
  components: {ComingSoonBanner},
  data() {
    return {}
  }
}
</script>

